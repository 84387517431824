<script setup lang="ts">
    import GenericBreadcrumb from '@/common/components/GenericBreadcrumb.vue';
    import { Navigation } from '@/router/navigation';
    import { useI18n } from 'vue-i18n';
    import TwoColumnGrid from '@/common/components/TwoColumnGrid.vue';
    import TwoColumnGridItemWithTitle from '@/common/components/TwoColumnGridItemWithTitle.vue';
    import TwoColumnGridItem from '@/common/components/TwoColumnGridItem.vue';
    import { usePriceFormatter } from '@/composables/formatPrice';
    import { useAsyncTask } from 'vue-concurrency';
    import { useOrderAction } from '../composables/order';
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import { useDateFormatter } from '@/composables/date-format';
    import useGlobalToast from '@/composables/useGlobalToast';
    import OrderItemInformation from '../components/OrderItemInformation.vue';
    import {
        DepotType,
        PaymentMethod,
        ProvisionType,
        TransportType,
        type OrderDto,
    } from '@containex/portal-backend-dto';
    import { isStringEmpty } from '@containex/common-utils';
    import { getLogger } from '@containex/logger';
    import { RouterLink, type RouteLocationRaw } from 'vue-router';
    import PopOver from 'primevue/popover';
    import { computed, ref, type ComputedRef } from 'vue';
    import { format } from 'date-fns';
    import { getRentalDuration } from '@containex/portal-business-logic';
    import Chip from 'primevue/chip';
    import { setDynamicTitle } from '@/util/pageTitleUtils.ts';

    const props = defineProps<{
        id: string;
    }>();

    const logger = getLogger('OrderDetailView');
    const { t } = useI18n();
    const { formatPrice } = usePriceFormatter();
    const { dateFormatter } = useDateFormatter();

    const paymentInfoVisible = ref();

    const orderAction = useOrderAction();
    const title = `${t('ACCOUNT.ORDER_DETAIL.TITLE')} ${props.id}`;

    let order: OrderDto | undefined;
    const fetchOrderTask = useAsyncTask(async () => {
        try {
            order = await orderAction.loadOrder(props.id);
        } catch (error) {
            logger.error(error);
            const { errorToastOptions, addToast } = useGlobalToast();
            addToast(errorToastOptions);
        }
    }).perform();

    const rentalOrderCranageInformation: ComputedRef<{ quantity: number; costs: number } | undefined> = computed(() => {
        //eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        if (order == null || !order.is_rental) {
            return undefined;
        }

        let costs = 0;
        let quantity = 0;
        order.line_item_groups[0]?.items
            .filter((item) => item.parent_line_item_id != null)
            .forEach((item) => {
                costs += item.total_amount;
                quantity += item.quantity;
            });

        return { quantity, costs };
    });

    setDynamicTitle(props.id);

    function getLinkToProduct(
        provisionType: ProvisionType,
        articleId: string,
        variantId: string | null | undefined
    ): RouteLocationRaw {
        const params = {
            id: articleId,
            variantId: variantId ?? undefined,
        };

        switch (provisionType) {
            case ProvisionType.Sales:
                return {
                    name: Navigation.SalesProductDetail,
                    params,
                };
            case ProvisionType.Rental:
                return {
                    name: Navigation.RentalProductDetail,
                    params,
                };
        }
    }

    function formatTransportCosts(depotType: DepotType, transportCosts: number | undefined | null): string {
        if (depotType === DepotType.ProductionSite) {
            return t('COMMON.FREE');
        }

        if (transportCosts == null) {
            return t('ACCOUNT.ORDER_DETAIL.TRANSPORT_COST.REQUEST');
        } else if (transportCosts === 0) {
            return t('COMMON.FREE');
        }

        return formatPrice(transportCosts);
    }

    function formatTransportType(transportType: TransportType): string {
        switch (transportType) {
            case TransportType.CraneTruck:
                return t('ACCOUNT.ORDER_DETAIL.TRANSPORT_TYPE.LKK');
            case TransportType.Truck:
                return t('ACCOUNT.ORDER_DETAIL.TRANSPORT_TYPE.LKW');
            case TransportType.PickUp:
                return t('ACCOUNT.ORDER_DETAIL.TRANSPORT_TYPE.PICKUP');
        }
    }

    function togglePaymentInfo(event: MouseEvent): void {
        //eslint-disable-next-line  @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        paymentInfoVisible.value.toggle(event);
    }

    function getDepotName(isRentalOrder: boolean, city: string | null | undefined): string | undefined {
        if (!isRentalOrder) {
            return city != null
                ? `${t('ACCOUNT.ORDER_DETAIL.DEPOT')}: ${city}`
                : t('ACCOUNT.ORDER_DETAIL.FROM_FACTORY');
        }

        return undefined;
    }
</script>
<template>
    <GenericBreadcrumb
        :back="[{ link: Navigation.OrderList, title: t('NAVIGATION.MENU_ITEM.ORDERS') }]"
        :title="title"
    ></GenericBreadcrumb>

    <div v-if="!fetchOrderTask.isRunning && order != null" class="content">
        <div class="header">
            <span>
                <i class="pi pi-shopping-cart circle-icon"></i>
            </span>
            <div class="header-content">
                <h2 class="text-2xl-bold-line-height-150">{{ title }}</h2>
                <div class="text-md-regular-line-height-auto subtitle">
                    {{
                        t('ACCOUNT.ORDER_DETAIL.SUBTITLE', {
                            created_at: dateFormatter.formatDateTwoDigits(new Date(order.created_at)),
                            name: order.orderer.customer_name,
                        })
                    }}
                </div>
                <div class="text-md-regular-line-height-auto subtitle">
                    {{ t('ACCOUNT.ORDER_DETAIL.PROVISION_TYPE') }}:
                    {{
                        order.provision_type === ProvisionType.Sales
                            ? t('ACCOUNT.ORDER_DETAIL.SALES')
                            : t('ACCOUNT.ORDER_DETAIL.RENTAL')
                    }}
                </div>
            </div>
        </div>

        <h3 class="text-xl-bold-line-height-auto">{{ t('ACCOUNT.ORDER_DETAIL.INFORMATION') }}</h3>
        <div class="grid">
            <TwoColumnGrid>
                <template #gridcontent>
                    <TwoColumnGridItemWithTitle :title="t('ACCOUNT.ORDER_DETAIL.ORDERER')" :show-divider="true">
                        <template #content>
                            {{ order.orderer.company_name }}<br />
                            <span v-if="order.orderer.external_id != null"
                                >{{ t('ACCOUNT.ORDER_DETAIL.COMPANY_ID') }}: {{ order.orderer.external_id }}</span
                            ><br />
                            <span v-if="order.orderer.uid != null"
                                >{{ t('ACCOUNT.ORDER_DETAIL.UID') }}: {{ order.orderer.uid }}</span
                            ><br />
                            {{ order.orderer.street }}<br />
                            {{ order.orderer.country_code?.toUpperCase() }}-{{ order.orderer.postal_code }}
                            {{ order.orderer.city }}
                        </template>
                    </TwoColumnGridItemWithTitle>
                    <TwoColumnGridItemWithTitle
                        v-if="order.is_rental && order.rental_start != null && order.rental_end != null"
                        :title="t('ACCOUNT.ORDER_DETAIL.RENTAL_TIME')"
                        :show-divider="true"
                    >
                        <template #content>
                            {{
                                dateFormatter.formatDateTwoDigits(
                                    new Date(format(order.rental_start ?? '', 'yyyy-MM-dd'))
                                )
                            }}
                            -
                            {{
                                dateFormatter.formatDateTwoDigits(
                                    new Date(format(order.rental_end ?? '', 'yyyy-MM-dd'))
                                )
                            }}
                            <br />
                            {{ getRentalDuration(new Date(order.rental_start), new Date(order.rental_end)) }}
                            {{ t('COMMON.CALENDAR_DAYS') }}
                        </template>
                    </TwoColumnGridItemWithTitle>
                    <TwoColumnGridItemWithTitle :title="t('ACCOUNT.ORDER_DETAIL.REFERENCE')" :show-divider="true">
                        <template #content>{{ !isStringEmpty(order.reference) ? order.reference : '-' }}</template>
                    </TwoColumnGridItemWithTitle>
                    <TwoColumnGridItemWithTitle :title="t('ACCOUNT.ORDER_DETAIL.NOTES')" :show-divider="false">
                        <template #content>{{ !isStringEmpty(order.note) ? order.note : '-' }}</template>
                    </TwoColumnGridItemWithTitle>
                </template>
            </TwoColumnGrid>

            <TwoColumnGrid>
                <template #gridcontent>
                    <TwoColumnGridItemWithTitle
                        v-if="order.is_rental && order.line_item_groups[0]?.transport_type !== TransportType.PickUp"
                        :title="t('ACCOUNT.ORDER_DETAIL.DELIVERY_ADDRESS')"
                        :show-divider="true"
                    >
                        <template #content>
                            <template v-if="order.delivery_address.company_name"
                                >{{ order.delivery_address.company_name }}<br
                            /></template>
                            {{ order.delivery_address.street }}<br />
                            {{ order.delivery_address.country_code?.toUpperCase() }}-{{
                                order.delivery_address.postal_code
                            }}
                            {{ order.delivery_address.city }}
                        </template>
                    </TwoColumnGridItemWithTitle>
                    <TwoColumnGridItemWithTitle
                        v-else-if="order.is_rental"
                        :title="t('ACCOUNT.ORDER_DETAIL.PICKUP_ADDRESS')"
                        :show-divider="true"
                    >
                        <template v-if="order.line_item_groups[0] != null" #content>
                            <template v-if="order.line_item_groups[0].depot.name != null"
                                >{{ order.line_item_groups[0].depot.name }}<br
                            /></template>
                            {{ order.line_item_groups[0].depot.street }}<br />
                            {{ order.line_item_groups[0].depot.country_code?.toUpperCase() }}-{{
                                order.line_item_groups[0].depot.postal_code
                            }}
                            {{ order.line_item_groups[0].depot.city }}
                        </template>
                    </TwoColumnGridItemWithTitle>
                    <TwoColumnGridItemWithTitle :title="t('ACCOUNT.ORDER_DETAIL.BILLING_ADDRESS')" :show-divider="true">
                        <template #content>
                            {{ order.billing_address.company_name }}<br />
                            {{ order.billing_address.street }}<br />
                            {{ order.billing_address.country_code?.toUpperCase() }}-{{
                                order.billing_address.postal_code
                            }}
                            {{ order.billing_address.city }}
                        </template>
                    </TwoColumnGridItemWithTitle>
                    <TwoColumnGridItemWithTitle :title="t('ACCOUNT.ORDER_DETAIL.PAYMENT_TYPE')" :show-divider="true">
                        <template #content>
                            <div class="payment-info">
                                <span
                                    >{{
                                        order.payment_term === PaymentMethod.OnAccount
                                            ? t('ACCOUNT.ORDER_DETAIL.PAYMENT_ON_ACCOUNT')
                                            : t('ACCOUNT.ORDER_DETAIL.PAYMENT_PREPAID')
                                    }}
                                </span>
                                <span class="pi pi-info-circle pointer" @click="togglePaymentInfo"></span>
                            </div>
                            <PopOver ref="paymentInfoVisible">
                                <div class="payment-info-popup">{{ t('CHECKOUT.PAYMENT.ON_ACCOUNT.NOTE') }}</div>
                            </PopOver>
                        </template>
                    </TwoColumnGridItemWithTitle>
                    <TwoColumnGridItemWithTitle
                        :title="t('ACCOUNT.ORDER_DETAIL.TOTAL_AMOUNT_EXCL_TAX')"
                        :show-divider="false"
                    >
                        <template #content>
                            {{ formatPrice(order.total_amount) }}
                        </template>
                    </TwoColumnGridItemWithTitle>
                </template>
            </TwoColumnGrid>
        </div>

        <h3 class="text-xl-bold-line-height-auto">{{ t('ACCOUNT.ORDER_DETAIL.POSITIONS') }}</h3>
        <div class="order-items">
            <template v-for="group of order.line_item_groups" :key="group">
                <TwoColumnGrid :title="getDepotName(order.is_rental, group.depot.city)">
                    <template #gridcontent>
                        <template v-for="item of group.items" :key="item">
                            <TwoColumnGridItem v-if="item.parent_line_item_id == null" :show-divider="true">
                                <template #left>
                                    <div class="order-item">
                                        <img
                                            v-if="item.thumbnail != null && !isStringEmpty(item.thumbnail)"
                                            :src="item.thumbnail"
                                            class="order-image"
                                        />
                                        <img v-else src="/assets/images/containex.svg" class="order-image" />
                                        <div class="order-item-details">
                                            <h4 class="text-base-bold-line-height-auto order-item-header">
                                                <span>{{ item.name }}</span>
                                                <span>{{ formatPrice(item.total_amount) }}</span>
                                            </h4>
                                            <div class="text-base-regular-line-height-auto order-item-box">
                                                <div>
                                                    {{ t('ACCOUNT.ORDER_DETAIL.ARTICLE_ID') }}:<br />
                                                    {{ t('ACCOUNT.ORDER_DETAIL.UNIT_PRICE') }}:<br />
                                                    {{ t('ACCOUNT.ORDER_DETAIL.QUANTITY') }}:
                                                </div>
                                                <div>
                                                    {{ item.article_id }}<br />
                                                    <span v-if="!order.is_rental">
                                                        {{ formatPrice(item.unit_amount) }}<br />
                                                    </span>
                                                    <span
                                                        v-else-if="
                                                            order.is_rental &&
                                                            order.rental_end != null &&
                                                            order.rental_start != null
                                                        "
                                                        >{{
                                                            formatPrice(
                                                                item.unit_amount /
                                                                    getRentalDuration(
                                                                        new Date(order.rental_start),
                                                                        new Date(order.rental_end)
                                                                    )
                                                            )
                                                        }}
                                                        / {{ t('COMMON.DAY') }}<br
                                                    /></span>
                                                    {{ item.quantity }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="!item.is_additional_product && item.article_id != null"
                                                class="text-base-regular-line-height-auto"
                                            >
                                                <RouterLink
                                                    class="text-link text-base-regular-line-height-auto"
                                                    :to="
                                                        getLinkToProduct(
                                                            order.provision_type,
                                                            item.article_id,
                                                            item.variant_id
                                                        )
                                                    "
                                                >
                                                    {{ t('ACCOUNT.ORDER_DETAIL.OPEN_PRODUCT_DETAILS') }}
                                                </RouterLink>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template #right>
                                    <Chip
                                        v-if="item.ctx_order.length === 0"
                                        :label="t('ACCOUNT.ORDER_DETAIL.ORDER_PENDING')"
                                        class="order-item-information"
                                    />
                                    <template v-else>
                                        <template v-for="ctxOrder of item.ctx_order" :key="ctxOrder.id">
                                            <OrderItemInformation
                                                :information="ctxOrder"
                                                :is-rental="order.is_rental"
                                                class="order-item-information"
                                            />
                                        </template>
                                    </template>
                                </template>
                            </TwoColumnGridItem>
                        </template>
                        <template v-if="order.is_rental">
                            <template v-if="rentalOrderCranageInformation != null">
                                <TwoColumnGridItem :show-divider="true">
                                    <template #left>
                                        <div class="order-item">
                                            <div class="order-image">
                                                <div class="order-icon">
                                                    <img src="/assets/images/icon_crane.svg" alt="Crane icon" />
                                                </div>
                                            </div>
                                            <div class="order-item-details">
                                                <h4 class="text-base-bold-line-height-auto order-item-header">
                                                    <span>{{ t('ACCOUNT.ORDER_DETAIL.CRANAGE_OUT') }}</span>
                                                    <span>{{
                                                        formatPrice(rentalOrderCranageInformation.costs / 2)
                                                    }}</span>
                                                </h4>
                                                <div>
                                                    <div class="text-base-regular-line-height-auto">
                                                        {{ t('ACCOUNT.ORDER_DETAIL.QUANTITY') }}:
                                                        {{ rentalOrderCranageInformation.quantity / 2 }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </TwoColumnGridItem>
                                <TwoColumnGridItem
                                    :show-divider="(order.line_item_groups[0]?.transport_amount ?? 0) > 0"
                                >
                                    <template #left>
                                        <div class="order-item">
                                            <div class="order-image">
                                                <div class="order-icon">
                                                    <img src="/assets/images/icon_crane.svg" alt="Crane icon" />
                                                </div>
                                            </div>
                                            <div class="order-item-details">
                                                <h4 class="text-base-bold-line-height-auto order-item-header">
                                                    <span>{{ t('ACCOUNT.ORDER_DETAIL.CRANAGE_IN') }}</span>
                                                    <span>{{
                                                        formatPrice(rentalOrderCranageInformation.costs / 2)
                                                    }}</span>
                                                </h4>
                                                <div>
                                                    <div class="text-base-regular-line-height-auto">
                                                        {{ t('ACCOUNT.ORDER_DETAIL.QUANTITY') }}:
                                                        {{ rentalOrderCranageInformation.quantity / 2 }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </TwoColumnGridItem>
                            </template>
                            <template
                                v-if="
                                    order.line_item_groups[0]?.transport_amount != null &&
                                    order.line_item_groups[0]?.transport_amount > 0
                                "
                            >
                                <TwoColumnGridItem :show-divider="true">
                                    <template #left>
                                        <div class="order-item">
                                            <div class="order-image">
                                                <div class="order-icon">
                                                    <i class="pi pi-truck icon"></i>
                                                </div>
                                            </div>
                                            <div class="order-item-details">
                                                <h4 class="text-base-bold-line-height-auto order-item-header">
                                                    <span>{{ t('ACCOUNT.ORDER_DETAIL.DELIVERY_OUT') }}</span>
                                                    <span>{{
                                                        formatPrice(order.line_item_groups[0]?.transport_amount / 2)
                                                    }}</span>
                                                </h4>
                                            </div>
                                        </div>
                                    </template>
                                </TwoColumnGridItem>
                                <TwoColumnGridItem :show-divider="false">
                                    <template #left>
                                        <div class="order-item">
                                            <div class="order-image">
                                                <div class="order-icon">
                                                    <i class="pi pi-truck icon"></i>
                                                </div>
                                            </div>
                                            <div class="order-item-details">
                                                <h4 class="text-base-bold-line-height-auto order-item-header">
                                                    <span>{{ t('ACCOUNT.ORDER_DETAIL.DELIVERY_IN') }}</span>
                                                    <span>{{
                                                        formatPrice(
                                                            (order.line_item_groups[0]?.transport_amount ?? 0) / 2
                                                        )
                                                    }}</span>
                                                </h4>
                                            </div>
                                        </div>
                                    </template>
                                </TwoColumnGridItem>
                            </template>
                        </template>

                        <TwoColumnGridItem v-if="!order.is_rental" :show-divider="false">
                            <template #left>
                                <div class="order-item">
                                    <div class="order-image">
                                        <div class="order-icon">
                                            <i
                                                v-if="group.transport_type === TransportType.PickUp"
                                                class="pi pi-building icon"
                                            ></i>
                                            <i v-else class="pi pi-truck icon"></i>
                                        </div>
                                    </div>
                                    <div class="order-item-details">
                                        <h4 class="text-base-bold-line-height-auto order-item-header">
                                            <span>{{ formatTransportType(group.transport_type) }}</span>
                                            <span>{{
                                                formatTransportCosts(group.depot.type, group.transport_amount)
                                            }}</span>
                                        </h4>
                                        <template v-if="group.transport_type === TransportType.PickUp">
                                            <div>
                                                <h5 class="text-base-semibold-line-height-auto">
                                                    {{ t('ACCOUNT.ORDER_DETAIL.PICKUP_ADDRESS') }}
                                                </h5>
                                                <div class="text-base-regular-line-height-auto">
                                                    <span v-if="!isStringEmpty(group.depot.name)">
                                                        {{ group.depot.name }}<br />
                                                    </span>
                                                    <span v-if="!isStringEmpty(group.depot.street)">
                                                        {{ group.depot.street }}<br />
                                                    </span>
                                                    <span v-if="!isStringEmpty(group.depot.city)">
                                                        {{ group.depot.country_code?.toUpperCase() }}-{{
                                                            group.depot.postal_code
                                                        }}
                                                        {{ group.depot.city }}
                                                    </span>
                                                    <span v-if="!isStringEmpty(group.depot.name)">
                                                        {{ group.depot.name }}<br />
                                                    </span>
                                                    <span v-if="!isStringEmpty(group.depot.name)">
                                                        {{ group.depot.name }}<br />
                                                    </span>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div>
                                                <h5 class="text-base-semibold-line-height-auto">
                                                    {{ t('ACCOUNT.ORDER_DETAIL.DELIVERY_ADDRESS') }}
                                                </h5>
                                                <div class="text-base-regular-line-height-auto">
                                                    <span v-if="!isStringEmpty(order.delivery_address.company_name)">
                                                        {{ order.delivery_address.company_name }}<br />
                                                    </span>
                                                    <span v-if="!isStringEmpty(order.delivery_address.street)">
                                                        {{ order.delivery_address.street }}<br />
                                                    </span>
                                                    <span v-if="!isStringEmpty(order.delivery_address.city)">
                                                        {{ order.delivery_address.country_code?.toUpperCase() }}-{{
                                                            order.delivery_address.postal_code
                                                        }}
                                                        {{ order.delivery_address.city }}<br />
                                                    </span>
                                                </div>
                                            </div>
                                        </template>
                                        <div
                                            v-if="
                                                group.transport_date_start != null && group.transport_date_end != null
                                            "
                                        >
                                            <h5 class="text-base-semibold-line-height-auto">Wunschzeitraum</h5>
                                            <span>
                                                {{
                                                    dateFormatter.formatDateTwoDigits(
                                                        new Date(group.transport_date_start)
                                                    )
                                                }}
                                                -
                                                {{
                                                    dateFormatter.formatDateTwoDigits(
                                                        new Date(group.transport_date_end)
                                                    )
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </TwoColumnGridItem>
                    </template>
                </TwoColumnGrid>
            </template>
        </div>
    </div>
    <div v-else-if="fetchOrderTask.isRunning" class="loading-spinner">
        <LoadingSpinner></LoadingSpinner>
    </div>
    <div v-else>
        <strong>{{ t('ACCOUNT.ORDER_DETAIL.ERROR_LOADING') }}</strong>
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .loading-spinner {
        text-align: center;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-6;
    }

    .header {
        display: flex;
        justify-content: left;
        gap: main.$spacing-5;
        align-items: center;
    }

    .circle-icon {
        font-size: 1.5rem;
        background: main.$color-background-bluegray;
        padding: main.$spacing-5;
        border-radius: 50%;
    }

    .subtitle {
        color: main.$color-secondary;
    }

    .grid {
        display: grid;
        grid-template-columns: auto;
        gap: main.$spacing-5;
    }

    @include main.for-breakpoint-lg {
        .grid {
            grid-template-columns: auto auto;
        }
    }

    .box {
        border: 1px solid main.$color-border-gray;
        border-radius: 6px;
        padding: main.$spacing-5;
    }

    .order-items {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-6;
    }

    .order-item {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
        align-items: flex-start;
    }

    @include main.for-breakpoint-lg {
        .order-item {
            flex-direction: row;
            align-items: center;
        }
    }

    .order-item-header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .order-item-details {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: main.$spacing-4;
    }

    .order-item-box {
        display: flex;
        gap: main.$spacing-4;
    }

    .order-image {
        max-height: 75px;
        width: 75px;
        padding: main.$spacing-2;
    }

    .order-icon {
        width: 75px;
        text-align: center;
    }

    .icon {
        font-size: 1.5rem;
    }

    .payment-info {
        display: flex;
        gap: main.$spacing-4;
        align-items: center;
    }

    .pointer {
        cursor: pointer;
    }

    .payment-info-popup {
        display: block;
        width: 300px;
    }

    .order-item-information {
        margin-bottom: main.$spacing-5;
    }

    .order-item-information:last-of-type {
        margin-bottom: 0;
    }

    @include main.for-breakpoint-lg {
        .order-item-information {
            margin-left: main.$spacing-5;
        }
    }
</style>
